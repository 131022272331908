<template>
    <div class="grid admintext-demo">
        <Toolbar class="toolbar_top">
            <template v-slot:start>
                <div class="my-2">
                    <h3>{{ t('userManagement') }}</h3>
                </div>
            </template>
        </Toolbar>
        <div class="col-12">
            <div class="card">
                <Toast/>
                <!--        <div class="my-2">-->
                <!--          -->
                <!--        </div>-->
                <DataTable
                    class="p-datatable-gridlines table-filter-texte"
                    :value="users" :paginator="true" :rows="limitUsers"
                    responsiveLayout="scroll" :totalRecords="totalItemsUsers" :lazy="true" :loading="loadingUsers"
                    @page="onPage($event.page + 1, $event)" ref="dt"
                    :globalFilterFields="['firstname', 'lastname','email','department']"
                    v-model:filters="filters" filterDisplay="menu" :filters="filters"
                    @filter="handleFilterRequest($event)"
                >
                    <template #loading>
                        {{ t('LoadingDataPleaseWait') }}
                    </template>
                    <template #header>
                        <div class="table-header-container">
                            <Button :label="t('addUser')" icon="pi pi-plus" class="add-text p-button-success mr-2"
                                    @click="openModalUser()"/>
                        </div>
                    </template>
                    <Column
                        field="firstname"
                        filterField="firstname"
                        :header="t('firstname')"
                        :showFilterMatchModes="false"
                    >
                        <template #body="{data}">
                            <span>{{ data.firstname }}</span>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                :placeholder="t('search')"
                            />
                        </template>
                    </Column>
                    <Column field="lastname" filterField="lastname" :header="t('lastname')"
                            :showFilterMatchModes="false">
                        <template #body="{data}">
                            <div class="tooltip">
                                {{ data.lastname }}
                            </div>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                :placeholder="t('search')"
                            />
                        </template>
                    </Column>
                    <Column field="email" filterField="email" :header="t('email')" :showFilterMatchModes="false">
                        <template #body="{data}">
                            <div class="tooltip">
                                {{ data.email }}
                            </div>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                :placeholder="t('search')"
                            />
                        </template>
                    </Column>
                    <Column field="department" filterField="department" :header="t('department')"
                            :showFilterMatchModes="false">
                        <template #body="{data}">
                            <div class="tooltip">
                                {{ data.department }}
                            </div>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText
                                type="text"
                                v-model="filterModel.value"
                                class="p-column-filter"
                                :placeholder="t('search')"
                            />
                        </template>
                    </Column>
                    <Column :header="t('update')" style="min-width:15rem" class="column_update">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="btn_edit button-table mr-2"
                                    @click="openModalUser(slotProps.data)"
                                    v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
                            <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                                    @click="deleteUser(slotProps.data)"
                                    v-tooltip.top="{value:t('delete'), class: 'editBtn'}"/>
                        </template>
                    </Column>
                </DataTable>
                <Dialog v-model:visible="userDialog" :style="{width: '900px'}" :header="titleModalUser" :modal="true"
                        class="modal_general p-fluid">
                    <TabView class="tabs_texte_admin tabview-custom">
                        <TabPanel>
                            <template #header>
                                <span>{{ t('details') }}</span>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="titre">Email* :</label>
                                    <InputText id="name" v-model="emailUser" required="true" autofocus
                                               :class="{'p-invalid': submitted && !emailUser}"/>
                                    <small class="p-invalid"
                                           v-if="submitted &&  !validateEmail(emailUser)">{{ t('invalidMail') }}</small>
                                </div>
                                <div class="field col" v-show="!verifRole">
                                </div>
                                <div class="field col" v-show="verifRole">
                                    <label for="langue">{{ t('customer') }} </label>
                                    <MultiSelect v-model="valuesCustomer" :options="customers" optionLabel="name"
                                                 placeholder="Select Brands"/>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="titre">{{ t('firstname') }}* :</label>
                                    <InputText id="name" v-model="user.firstname" required="true" autofocus
                                               :class="{'p-invalid': submitted && !user.firstname}"/>
                                    <small class="p-invalid"
                                           v-if="submitted && !user.firstname">{{ t('firstNameRequired') }}</small>
                                </div>
                                <div class="field col">
                                    <label for="titre">{{ t('lastname') }}* :</label>
                                    <InputText id="name" v-model="user.lastname" required="true" autofocus
                                               :class="{'p-invalid': submitted && !user.lastname}"/>
                                    <small class="p-invalid" v-if="submitted && !user.lastname">{{
                                            t('lastNameRequired')
                                        }}</small>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="titre">{{ t('department') }}* :</label>
                                    <InputText id="name" v-model="user.department" required="true" autofocus
                                               :class="{'p-invalid': submitted && !user.department}"/>
                                    <small class="p-invalid"
                                           v-if="submitted && !user.department">{{ t('departmentRequired') }}</small>
                                </div>
                                <div class="field col">
                                    <label for="titre">Fonction :</label>
                                    <InputText id="name" v-model="user.position" required="true" autofocus/>
                                    <!--                  <small class="p-invalid" v-if="submitted && (!user?.position || user?.position != '' )">{{ t('positionRequired') }}</small>-->
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="titre">{{ t('fixedTel') }} :</label>
                                    <InputText id="name" v-model="user.phone" required="true" autofocus
                                    />
                                </div>
                                <div class="field col">
                                    <label for="titre">Mobile :</label>
                                    <InputText id="name" v-model="user.mobile" required="true" autofocus
                                    />
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="commentaire">Site {{ t('or') }} service :</label>
                                    <Textarea id="commentaire" v-model="user.website" required="true" rows="5"
                                              cols="20"/>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <div class="field-radiobutton pt-4">
                                        <Checkbox id="checkOption1" name="option" value="is_enabled"
                                                  v-model="is_enabled"/>
                                        <label for="confidentiel">{{ t('activate') }}</label>
                                    </div>
                                </div>
                                <div class="field col">
                                    <div class="field-radiobutton pt-4">
                                        <Checkbox id="checkOption1" name="option" value="hide_AP" v-model="hide_AP"/>
                                        <label for="confidentiel">{{ t('hideInAP') }}</label>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span>{{ t('parameters') }}</span>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="langue">{{ t('language') }}<span>*</span> </label>
                                    <Dropdown v-model="valueLanguage" :options="valueLanguages" optionLabel="name"
                                              required="true"
                                              autofocus/>
                                </div>
                                <div class="field col">
                                    <label for="titre">{{ t('validityEndDate') }}:</label>
                                    <Calendar :showIcon="true" :showButtonBar="true" v-model="date_expire_at"
                                              dateFormat="dd/mm/yy"></Calendar>
                                </div>
                            </div>

                            <div class="formgrid grid" style="display: none">
                                <div class="field col">
                                    <label for="titre">{{ t('creationDate') }} :</label>
                                    <Calendar :showIcon="true" :showButtonBar="true" v-model="user.dated_at"></Calendar>
                                </div>
                                <div class="field col">
                                    <label for="statut">{{ t('modificationDate') }} :</label>
                                    <Calendar :showIcon="true" :showButtonBar="true"
                                              v-model="user.last_updated_at"></Calendar>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span>{{ t('accessRights') }}</span>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="commentaire">{{ t('userRole') }} :</label>
                                    <Dropdown v-model="valueStatusAffect" :options="valuesStatusAffect"
                                              optionLabel="label"/>
                                </div>
                                <div class="field col">
                                </div>
                            </div>
                            <TreeTable :value="treeSelectNodes" selectionMode="checkbox"
                                       v-model:selectionKeys="selectedNode" ref="userSitetree"
                                       @page="onPage($event.page + 1, $event)">
                                <Column :class="treeSelectNodes.key" field="name" header="Name" :expander="true">
                                    <template #editor="{ data, field }">
                                        <InputText v-model="data[field]" autofocus/>
                                    </template>
                                </Column>
                            </TreeTable>
                        </TabPanel>
                        <TabPanel :disabled="true">
                            <template #header>
                                <span>{{ t('statistics') }}</span>
                            </template>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="titre">{{ t('lastConnection') }} :</label>
                                    <Calendar :showIcon="true" :showButtonBar="true" v-model="user.dated_at"></Calendar>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="titre">{{ t('firstConnection') }} :</label>
                                    <Calendar :showIcon="true" :showButtonBar="true" v-model="user.dated_at"></Calendar>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="titre">{{ t('numberOfConnection') }} :</label>
                                    <InputText id="name" v-model="user.address" required="true" autofocus
                                    />

                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="titre">{{ t('averageTimePerConnection') }} :</label>
                                    <InputText id="name" v-model="user.address" required="true" autofocus
                                    />

                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="titre">{{ t('connected') }} :</label>
                                    <InputText id="name" v-model="user.address" required="true" autofocus
                                    />

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <span>Avatar</span>
                            </template>
                            <div class="grid">
                                <div class="col-12">
                                    <div class="card">
                                        <FileUpload name="demo[]" accept="image/*" :maxFileSize="1000000"
                                                    @upload="onUpload"/>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                    <template #footer>
                        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                                @click="hideDialog"/>
                        <Button :label="t('save')" v-if="user.id" icon="pi pi-check " class="p-button-text next_btn"
                                @click="editUser()"/>
                        <Button :label="t('save')" v-else icon="pi pi-check" class="p-button-text next_btn"
                                @click="addUser()"/>
                    </template>
                </Dialog>
                <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}"
                               :style="{width: '50vw'}"></ConfirmDialog>
            </div>
        </div>
    </div>
    <Toast/>
</template>
<script>

import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {useToast} from "primevue/usetoast";
import {usePrimeVue} from "primevue/config";
import moment from "moment-timezone";
import {computed} from "vue";
import {FilterMatchMode} from "primevue/api";

export default {
    name: "AdminUser",
    data() {
        return {
            currentPage: 1,
            selectedUser: {},
            submitted: false,
            users: [],
            userDialog: false,
            user: {},
            valueLanguages: [
                {'id': 1, 'name': 'Français', 'code': 'fr'},
                {'id': 2, 'name': 'Anglais', 'code': 'en'},
                {'id': 3, 'name': 'Allemand ', 'code': 'al'},
            ],
            valueLanguage: null,

            titleModalUser: '',
            listValuesSite: [[], []],
            sites: {},
            valueStatusAffect: '',
            valuesStatusAffect: [
                {id: 0, label: 'Administrateur', code: 'ROLE_ADMIN', is_applicable: false},
                {id: 1, label: 'Modificateur', code: 'ROLE_USER', is_applicable: true},
            ],
            nbPageUsers: 1,
            totalItemsUsers: '',
            loadingUsers: false,
            date_expire_at: '',
            customers: [],
            valuesCustomer: [],
            verifRole: true,
            limitUsers: 10,
            nbTotalPages: 1,
            is_enabled: ['is_enabled'],
            hide_AP: [],
            emailModif: '',
            idCustomer: '',
            emailUser: '',
            cars: [
                {brand: 'Audi', value: 'Audi'},
                {brand: 'BMW', value: 'BMW'},
                {brand: 'Fiat', value: 'Fiat'},
                {brand: 'Honda', value: 'Honda'},
                {brand: 'Jaguar', value: 'Jaguar'},
                {brand: 'Mercedes', value: 'Mercedes'},
                {brand: 'Renault', value: 'Renault'},
                {brand: 'Volkswagen', value: 'Volkswagen'},
                {brand: 'Volvo', value: 'Volvo'}
            ],
            filters: {
                'firstname': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'lastname': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'email': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'department': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            treeSelectNodes: [],
            selectedNode: [],
            filterObject: {},

            selectedCars: null,


        }
    },
    setup() {

        const store = useStore();
        const toast = useToast();
        const primevue = usePrimeVue();
        const userConnect = computed(() => {
            return store.getters.currentUser
        });
        const error = computed(() => {
            return store.getters.getErrors
        });

        return {store, toast, primevue, t: primevue.config.StoreTranslation.t, userConnect, error};
    },
    UserService: null,

    watch: {
        userConnect() {
            if (this.userConnect.application_master) {
                this.getCustomers()
            } else if (this.userConnect?.role_user && this.userConnect.role_user[0] == 'ROLE_ADMIN') {

                this.user.customer = this.userConnect?.customer?.id
                this.idCustomer = this.userConnect?.customer?.id
                this.verifRole = false
            }

        }
    },
    async mounted() {
        this.loadingUsers = true
        await this.getSites();
        await this.getUsers(1, this.filterObject)

        if (this.userConnect.role_user[0] == 'ROLE_ADMIN') {
            this.getCustomers()
        } else {
            this.verifRole = false
        }
    },
    methods: {
        getUsers(page = 1, customFilter = null) {
            let defaultPayload = {'customHeader': true, page: page, limit: this.limitUsers, customer: this.idCustomer}
            if (customFilter != null && typeof customFilter === "object")
                defaultPayload = Object.assign({}, defaultPayload, customFilter);

            this.loadingUsers = true
            return this.store.dispatch(Actions.GET_USERS, defaultPayload).then(data => {
                this.loadingUsers = false
                this.users = data['hydra:member']
                this.totalItemsUsers = data['hydra:totalItems']
            })
        },
        openModalUser(user) {
            this.user = {}
            this.emailUser = ''
            this.valuesCustomer = []
            this.date_expire_at = ''
            this.valueStatusAffect = ''
            this.valueLanguage = null
            this.selectedUser = user
            this.submitted = false;
            this.userDialog = true;

            if (this.userConnect.role_user[0] == 'ROLE_ADMIN') {
                // this.getCustomers()
            } else {
                this.verifRole = false
            }
            // valueLanguages: [
            //   {'id': 1, 'name': 'Français','code': 'fr'},
            //   {'id': 2, 'name': 'Anglais', 'code': 'en'},
            //   {'id': 3, 'name': 'Almand', 'code': 'al'},
            // ],
            if (user) {

                // this.valuesCustomer = []
                this.is_enabled = []
                this.hide_AP = []
                this.getSiteUser(user)
                this.getAllSitesTree()
                this.titleModalUser = this.t('editUser')
                this.store.dispatch(Actions.GET_USER_BY_ID, {id: user.id, 'customHeader': false}).then(data => {
                    this.user.firstname = data.firstname
                    this.user.lastname = data.lastname
                    this.user.department = data.department
                    this.user.position = data.position
                    // this.user.email = data.email
                    this.emailUser = data.email
                    this.emailModif = data.email
                    this.user.phone = data.phone
                    this.user.mobile = data.mobile
                    this.user.website = data.website
                    this.user.id = data.id
                    data.customer_associated?.forEach(element => {
                        let objectCustomer = this.customers.find(item => item.id == element)
                        this.valuesCustomer.push(objectCustomer)
                    })
                    // this.valuesCustomer = data.customer
                    let objLang = this.valueLanguages.find(elem => elem.code == data.lang)
                    this.valueLanguage = objLang
                    if (moment(data.expire_at).isValid()) {
                        this.date_expire_at = moment(data.expire_at).format('DD/MM/yy')
                    }
                    if (data.is_enabled) {
                        this.is_enabled[0] = 'is_enabled'

                    }
                    if (data.hide_in_p_a) {
                        this.hide_AP[0] = 'hide_AP'

                    }
                    this.valueStatusAffect = this.valuesStatusAffect.find(item => item.code == data.roles[0])


                })

            } else {
                this.getAllSitesTree()
                // this.getSites()


                this.titleModalUser = this.t('addUser')
            }
        },
        onUpload() {
            // this.$toast.add({severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000});
        },
        addUser() {
            this.error = ''
            this.submitted = true
            let affectations_site = [];
            if (this.is_enabled) {
                this.user.is_enabled = true
            } else {
                this.user.is_enabled = false
            }
            if (this.hide_AP.length > 0) {
                this.user.hide_in_p_a = true
            } else {
                this.user.hide_in_p_a = false
            }
            if (this.valueStatusAffect.id) {
                this.user.roles = ['ROLE_USER']
            } else {
                this.user.roles = ['ROLE_ADMIN']
            }
            if (moment(this.date_expire_at).isValid()) {
                this.user.expire_at = moment(this.date_expire_at).format()
            }
            for (const [key] of Object.entries(this.selectedNode)) {
                if (key != null && key != undefined) {
                    let filteredElement = this.sites.filter(function (site) {
                        return site.id == key;
                    });
                    if (filteredElement && filteredElement.length !== 0) {
                        let idSite = 'api/sites/' + key
                        affectations_site.push({'site': idSite})
                    }
                }
            }
            if (this.valueLanguage) {
                this.user.lang = this.valueLanguage.code
            }
            if (this.valuesCustomer?.length > 0) {
                this.user.customer_associated = []
                this.valuesCustomer.forEach(element => {
                    this.user.customer_associated.push((element.id).toString())
                })
            }
            this.user.user_sites = affectations_site
            let re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+$/;
            this.user.email = this.emailUser
            if (this.user.email && this.user.firstname && this.user.lastname && this.user.department && re.test(this.user.email)) {
                this.store.dispatch(Actions.ADD_USER, this.user).then(() => {
                    this.submitted = false
                    if (this.error == 422) {
                        this.toast.add({
                            severity: 'error',
                            detail: this.t('emailUsed'),
                            life: 9000
                        });
                        this.userDialog = false;
                    } else {
                        this.emailExit = false
                        // this.users.push(data)
                        this.userDialog = false;
                        this.toast.add({
                            severity: 'success',
                            detail: this.t('userHasSuccessfullyAdded'),
                            life: 3000
                        });
                        this.selectedNode = []
                        // this.onPage(1)
                        this.getUsers(this.nbTotalPages, this.filterObject)
                    }

                })


            }
        },
        editUser() {
            this.submitted = true;
            let affectations_site = [];
            if (this.valueStatusAffect.id) {
                this.user.roles = ['ROLE_USER']
            } else {
                this.user.roles = ['ROLE_ADMIN']
            }
            if (this.is_enabled) {
                this.user.is_enabled = true
            } else {
                this.user.is_enabled = false
            }
            if (this.hide_AP.length > 0) {
                this.user.hide_in_p_a = true
            } else {
                this.user.hide_in_p_a = false
            }
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(this.selectedNode)) {
                if (key != null && key != undefined) {

                    let filteredElement = this.sites.filter(function (site) {
                        return site.id == key;
                    });
                    if (filteredElement && filteredElement.length !== 0) {
                        let idSite = 'api/sites/' + key
                        // affectations_site.push({'site': idSite, 'is_applicable': element.is_applicable}

                        affectations_site.push({'site': idSite})
                    }
                }
            }

            if (affectations_site.length > 0) {
                this.user.user_sites = affectations_site
            }
            if (this.valueLanguage) {
                this.user.lang = this.valueLanguage.code
            }
            // if(this.valuesCustomer) {
            //   this.user.customer = 'api/customers/' + this.valuesCustomer.id
            // }
            if (this.valuesCustomer?.length > 0) {
                this.user.customer_associated = []
                this.valuesCustomer.forEach(element => {
                    this.user.customer_associated.push((element.id).toString())
                })
                // this.user.customer = 'api/customers/' + this.valuesCustomer.id
            }
            if (moment(this.date_expire_at).isValid()) {
                this.user.expire_at = moment(this.date_expire_at).format()
            }
            let modifEmail = true
            this.user.email = this.emailUser
            if (this.user.email == this.emailModif) {
                modifEmail = false
                delete this.user['email']
            }
            let re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+$/;

            // this.submitted = true
            if ((this.user.email || !modifEmail) && this.user.firstname && this.user.lastname && this.user.department && re.test(this.emailUser)) {

                if (this.error == 422) {
                    this.toast.add({
                        severity: 'error',
                        detail: this.t('emailUsed'),
                        life: 9000
                    });
                    this.userDialog = false;
                } else {
                    this.store.dispatch(Actions.PUT_USER, this.user).then(() => {
                        this.userDialog = false;
                        this.toast.add({
                            severity: 'success',
                            detail: this.t('userHasSuccessfullyModified'),
                            life: 3000
                        });
                        this.selectedNode = []
                        this.getUsers(this.currentPage, this.filterObject)
                    })
                }
            }
        },
        deleteUser(user) {
            this.$confirm.require({
                message: 'Êtes-vous sûr de vouloir supprimer?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {

                    this.store.dispatch(Actions.DELETE_USER, user.id).then(() => {
                        // this.users = this.users.filter(item => !(item.id == user.id))
                        this.onPage(1)
                        this.toast.add({
                            severity: 'success',
                            detail: this.t('userHasSuccessfullyDeleted'),
                            life: 3000
                        });

                    })
                },
                reject: () => {
                    //callback to execute when user rejects the action
                },
                onHide: () => {
                    //Callback to execute when dialog is hidden
                }
            });

        },
        hideDialog() {
            this.userDialog = false
        },
        getSites() {
            this.listValuesSite[0] = []

            return this.store.dispatch(Actions.GET_SITES_ALL, {'customHeader': false}).then(data => {
                this.listValuesSite[1] = data
                this.sites = data
                // if (user) {
                //   user.user_sites.forEach(element => {
                //     element.site.name = element.site.name + '';
                //     element.site.is_applicable = element.is_applicable;
                //     if (element.site.is_applicable) {
                //       element.site.typeLabel = 'Modificateur'
                //     } else {
                //       element.site.typeLabel = 'Administrateur'
                //     }
                //     element.site.code = element.site.code+''
                //     this.listValuesSite[0].push(element.site)
                //     this.listValuesSite[1] = this.listValuesSite[1].filter(item => !(item.id == element.site.id))
                //
                //   })
                //
                // }
            })

        },

        getSiteUser(user) {
            user.user_sites.forEach(element => {
                element.site.name = element.site.name + '';
                element.site.is_applicable = element.is_applicable;
                if (element.site.is_applicable) {
                    element.site.typeLabel = 'Modificateur'
                } else {
                    element.site.typeLabel = 'Administrateur'
                }
                element.site.code = element.site.code + ''
                this.listValuesSite[0].push(element.site)
                this.listValuesSite[1] = this.listValuesSite[1].filter(item => !(item.id == element.site.id))

            })
        },
        onPage(page, event) {
            this.currentPage = page
            this.handleFilterRequest(event, page)
        },
        getCustomers() {
            this.store.dispatch(Actions.GET_CUSTOMERS_SITES, {'customHeader': false}).then(data => {
                this.customers = data
            })
        },
        validateEmail(mail) {

            if (mail) {
                let re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+$/;
                return re.test(mail);
            } else {
                return false
            }
            // return true
        },
        handleFilterRequest(event, page = 1) {
            this.filterObject = {}

            if (event?.filters) {
                Object.entries(event.filters).forEach(filter => {
                    const [key, value] = filter;
                    if (value.value !== null && value.value !== '')
                        this.filterObject[key] = value.value
                });
            }
            this.getUsers(page, this.filterObject)
        },
        getAllSitesTree() {
            let userSiteIds = this.getUserSiteIds()
            this.treeSelectNodes = []
            this.selectedNode = []
            this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {'customHeader': false}).then(data => {
                let selectedElement = []
                // eslint-disable-next-line no-unused-vars
                let id;
                let formatSelectTree = (obj, indexCh = null) => {
                    obj?.forEach((element, index) => {
                        selectedElement[element.id] = {"checked": true}
                        if (element.parent_id)
                            selectedElement[element.parent_id] = {"checked": true}
                        let indexF = '0';
                        if (null === indexCh) {
                            indexF = index + '';
                        } else {
                            indexF = indexCh + '-' + index
                        }

                        if (element.entity_type === 'CUSTOMER') {
                            id = element.id
                        }

                        if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
                            Object.assign(element, {"children": element.childrens})
                            formatSelectTree(element.childrens, indexF)
                        }

                        if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
                            Object.assign(element, {
                                "key": element.id,
                                "data": {"name": element.site_name, 'id': element.id}
                            })
                            element.id = element.site_id
                        } else {
                            Object.assign(element, {
                                "key": element.id,
                                "data": {"name": element.name, 'id': element.id}
                            })
                        }
                        Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
                        Object.assign(element, {"key": element.id})

                        if (userSiteIds.includes(element.id)) {
                            selectedElement[element.id] = {"checked": true}
                            if (element.parent_id)
                                selectedElement[element.parent_id] = {"checked": true}
                            this.selectedNode = Object.assign({}, this.selectedNode, selectedElement)
                        }
                        selectedElement = []
                    });
                    return obj;
                };
                this.treeSelectNodes = formatSelectTree(data);
            })
            // }
        },
        getUserSiteIds() {
            let ids = []
            if (this.selectedUser) {
                this.selectedUser.user_sites.forEach(element => {
                    ids.push(element.site.id)
                })
            }
            return ids
        },
    },
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
